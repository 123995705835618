



























import { defineComponent, onMounted, Ref, ref } from '@vue/composition-api'
import { api } from 'plugins'
import { endpoints, toCamelCase, toSnakeCase, showError, getData } from 'utils'
import { ArItem, ArUnit, Body, BottomSheetButton } from 'typings'
import { ArItemDialog, MasterItemComponent } from 'components'
import moment from 'moment'

interface SetUp {
  arItems: Ref<Array<ArItem>>
  arUnits: Ref<Array<ArUnit>>
  showArItemDialog: Ref<boolean>
  isAdd: Ref<boolean>
  arItemProp: Ref<unknown>
  openArItemDialog: (arItem: ArItem) => void
  confirmReset: (param: string) => void
  getDataMaster: () => void
  saveSortOrder: (body: Body) => void
  isSorting: Ref<boolean>
  loading: Ref<boolean>
  bottomSheetListButton: Array<BottomSheetButton>
  vat: Ref<unknown>
}

const ArItems = defineComponent({
  components: {
    ArItemDialog,
    MasterItemComponent
  },
  props: {},
  setup(props, { root }): SetUp {
    const arItems = ref<Array<ArItem>>([])
    const arUnits = ref<Array<ArUnit>>([])
    const isSorting = ref(false)
    const arItemProp = ref({})
    const showArItemDialog = ref(false)
    const isAdd = ref(true)
    const { $toast } = root
    const vat = ref({})

    const loading = ref(false)

    const bottomSheetListButton: Array<BottomSheetButton> = [
      {
        id: 1,
        text: root.$t('master.ar_item.add') as string,
        classes: [],
        icon: 'mdi-plus',
        action: 'on-add',
        disabled: false
      },
      {
        id: 2,
        text: root.$t('master.ar_item.sort') as string,
        classes: [],
        icon: 'mdi-sort-ascending',
        action: 'on-sort',
        disabled: false
      },
      {
        id: 3,
        text: root.$t('common.cancel') as string,
        classes: ['red--text'],
        icon: '',
        action: 'on-close',
        disabled: false
      }
    ]

    const getDataMaster = async () => {
      const date = new Date()
      const today = moment(date).format('YYYY-MM-DD')
      const data = await getData(['arItem', 'arUnit', 'vat'], {}, { date: today })
      arItems.value = data.arItems ? toCamelCase(data.arItems) : []
      arUnits.value = data.arUnits ? toCamelCase(data.arUnits) : []
      vat.value = data.vat ? toCamelCase(data.vat) : {}
    }

    const saveSortOrder = async (body: Body): Promise<void> => {
      const params = toSnakeCase(body)
      try {
        await api.put(`${endpoints.AR_ITEM}sort`, params)
        $toast.success(root.$t('master.msg.save_order_successful'))
        isSorting.value = false
        await getDataMaster()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.save_order_failed') as string)
      }
    }

    const openArItemDialog = (arItem: ArItem | null): void => {
      if (arItem) {
        arItemProp.value = JSON.parse(JSON.stringify(arItem))
        isAdd.value = false
      } else {
        // default ar item prop
        arItemProp.value = {
          name: '',
          yomi: '',
          shortName: '',
          code: null,
          defaultUnitId: 1,
          taxRate: 'rate',
          isNegative: 'false',
          unitPrice: null,
          taxType: 'excluded'
        }
        isAdd.value = true
      }
      showArItemDialog.value = true
    }

    const confirmReset = async (): Promise<void> => {
      $toast.info('Ar Items reset')
      await getDataMaster()
    }

    onMounted(() => {
      getDataMaster()
    })

    return {
      arItems,
      arUnits,
      arItemProp,
      loading,
      showArItemDialog,
      isAdd,
      isSorting,
      vat,
      getDataMaster,
      confirmReset,
      openArItemDialog,
      saveSortOrder,
      bottomSheetListButton
    }
  }
})

export default ArItems
